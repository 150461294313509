import { FC, useEffect, useState } from "react";

import ThemedHeader from "./components/themed-header";

const Header: FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <ThemedHeader themeName={isScrolled ? "primary" : "inverted"} />;
};

export default Header;
