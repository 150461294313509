import { FC, useEffect } from "react";
import { Outlet, useRouteError, isRouteErrorResponse } from "@remix-run/react";
import { LinksFunction } from "@remix-run/cloudflare";

import defaultImage from "meta/default.png";

import { PRODUCTION_DOMAIN } from "constants/domains";

import ErrorBoundaryScreen from "components/error-boundary";
import Header from "components/header";
import Template from "components/template";
import NotFound from "components/not-found";

import "./styles/global.scss";
import { createMeta } from "./operations/meta";

const App: FC = () => {
  useEffect(() => {
    window.extensionScripts.forEach(script => {
      document.documentElement.appendChild(script);
    });
  }, []);

  return (
    <Template>
      <Header />
      <Outlet />
    </Template>
  );
};

export const links: LinksFunction = () => [
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: `${PRODUCTION_DOMAIN}/assets/favicon.png`,
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: `${PRODUCTION_DOMAIN}/assets/apple-touch-icon.png`,
  },
  { rel: "stylesheet", href: "/fonts/fonts.css" },
];

export const meta = createMeta(({ error }) => {
  let title = "Coindisco | Buy Crypto in Style";
  if (isRouteErrorResponse(error)) {
    title = error.status === 404 ? "404 Not found" : "Error occurred";
  }

  return {
    isRoot: true,
    title,
    description:
      "Discover low-fee crypto gems with access to the most relevant payment information from trusted financial providers. Available for mobile and desktop",
    ogImage: defaultImage,
  };
});

export const ErrorBoundary: FC = () => {
  const error = useRouteError();

  useEffect(() => {
    window.extensionScripts.forEach(script => {
      document.documentElement.appendChild(script);
    });
  }, []);

  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <Template>
      {isRouteErrorResponse(error) && error.status === 404 ? (
        <>
          <Header />
          <NotFound />
        </>
      ) : (
        <ErrorBoundaryScreen error={error as Error} />
      )}
    </Template>
  );
};

export default App;
