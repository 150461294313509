import { ReactNode, useEffect, useMemo, useState } from "react";

import { DialogRender } from "types/dialog";

import dialogAPI from "operations/dialog";

import classes from "./styles/classes.module.scss";

const Dialog = () => {
  const [dialogs, setDialogs] = useState<Map<string, DialogRender>>(new Map());

  const { alerts, fullscreenDialogs } = useMemo(() => {
    const alerts: ReactNode[] = [];
    const fullscreenDialogs: ReactNode[] = [];

    dialogs.forEach(({ node, type }) => {
      if (type === "alert") {
        alerts.push(node);
      } else {
        fullscreenDialogs.push(node);
      }
    });

    return { alerts, fullscreenDialogs };
  }, [dialogs]);

  dialogAPI.connectRender(setDialogs);

  useEffect(() => {
    document.body.style.overflow = fullscreenDialogs.length ? "hidden" : "";
  }, [fullscreenDialogs.length]);

  return (
    <>
      <div>{fullscreenDialogs}</div>
      {alerts.length > 0 && (
        <div className={classes.alertsWrapper}>{alerts}</div>
      )}
    </>
  );
};

export default Dialog;
