import { Link, useLocation, useNavigation } from "@remix-run/react";
import joinClassNames from "classnames";

import LogoIcon from "icons/logo.svg?react";

import { LineLoader } from "basics/loader";
import Button, { Sizes, Themes as ButtonThemes } from "basics/button";

import { Pages } from "constants/pages";
import { APP_URL } from "constants/domains";

import useWindowSizes from "hooks/use-window-sizes";

import { Themes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  themeName: Themes;
}

const ThemedHeader = ({ themeName }: Props) => {
  const { state } = useNavigation();
  const { isMobileWidth, isMobileSmallWidth, isTabletWidth } = useWindowSizes();
  const { pathname } = useLocation();

  const isPrimary =
    themeName === "primary" || (themeName === "inverted" && pathname !== "/");

  let buttonSize: Sizes = "lg";
  if (isMobileWidth) {
    buttonSize = "md";
  }
  if (isMobileSmallWidth) {
    buttonSize = "sm";
  }

  let buttonTheme: ButtonThemes = "default";
  const isTabletSmaller = isTabletWidth || isMobileWidth;
  if (isTabletSmaller) {
    buttonTheme = "inverted";
  }
  if (isPrimary) {
    buttonTheme = "primary";
  }

  return (
    <header
      className={joinClassNames(classes.wrapper, {
        [classes.primary]: themeName === "primary",
      })}
    >
      {state === "loading" && <LineLoader className={classes.lineLoader} />}
      <div className={classes.contentWrapper}>
        <Link to={Pages.home} className={classes.logoLink}>
          <LogoIcon
            className={joinClassNames(classes.logoIcon, {
              [classes.primaryLogo]: isPrimary,
            })}
          />
          <span className={classes.logoText}>Coindisco</span>
        </Link>
        <Button
          to={Pages.points}
          as={Link}
          size={buttonSize}
          themeName="ghost"
          className={joinClassNames(classes.pointsLink, {
            [classes.primaryPoints]: !isPrimary && isTabletSmaller,
          })}
        >
          Points
        </Button>
        <Button
          as="a"
          size={buttonSize}
          themeName={buttonTheme}
          target="_blank"
          href={APP_URL}
        >
          Launch app
        </Button>
      </div>
    </header>
  );
};

export default ThemedHeader;
