import { FC, PropsWithChildren, ReactNode } from "react";
import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";

import useNonce from "hooks/use-nonce";

import Footer from "./components/footer";
import Dialog from "../dialog";

interface Props extends Required<PropsWithChildren> {
  head?: ReactNode;
}

const Template: FC<Props> = ({ head, children }) => {
  const nonce = useNonce();

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {head}
        <Meta />
        <Links />
        <link rel="stylesheet" href="/fonts/fonts.css" />
      </head>
      <body>
        {children}
        <Footer />
        <ScrollRestoration nonce={nonce} />
        <Dialog />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
};

export default Template;
